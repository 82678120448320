import { useMemo } from 'react'

const findByTerm = (item: any, field: string, term: string): boolean => {
  if (Array.isArray(item) && item.length > 0) {
    return item.some((subItem) => findByTerm(subItem, field, term))
  }

  return (
    typeof item[field] === 'string' && item[field].toLowerCase().includes(term)
  )
}

const useFilterData = (
  data: any[] = [],
  searchParam?: string,
  fields: string[] = []
) => {
  const filteredData = useMemo(() => {
    if (!Array.isArray(data) || !Array.isArray(fields)) {
      return data
    }

    if (searchParam) {
      const lowerCaseSearchParam = searchParam.toLowerCase()

      return data.filter((item) =>
        fields.some((field) => {
          const keys = field.split('.')
          
          if (Array.isArray(item[keys[0]])) {
            return findByTerm(item[keys[0]], keys[1], lowerCaseSearchParam)
          }

          return findByTerm(item, field, lowerCaseSearchParam)
        })
      )
    }

    return data
  }, [data, searchParam, fields])

  return filteredData
}

export default useFilterData
