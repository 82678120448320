import { tv, VariantProps } from '@/utils/tv'

export const dynamicReviews = tv({
  slots: {
    base: ['relative', 'py-10 lg:py-20', 'overflow-hidden'],
    wrapper: ['px-6 lg:px-8', 'w-full max-w-7xl', 'mx-auto'],
    header: ['max-w-lg lg:max-w-full mx-auto text-center mb-12'],
    title: ['w-full'],
    carousel: ['w-full', '!overflow-visible', 'flex-wrap items-stretch'],
    nav: [
      'hidden lg:flex rtl:lg:flex-row-reverse',
      'absolute -top-24 ltr:right-0 rtl:left-0'
    ]
  }
})

export type DynamicReviewsVariantProps = VariantProps<typeof dynamicReviews>
