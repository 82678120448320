import { Button } from '../button'
import { useSwiperNav, useSwiperNavProps } from './use-swiper-nav'

export type SwiperNavProps = useSwiperNavProps

const SwiperNav = (props: SwiperNavProps) => {
  const { id, className, buttonColor, styles } = useSwiperNav(props)

  return (
    <div
      data-component-name="swiper-nav"
      className={styles.base({ className })}
    >
      <Button
        className={`prev-carousel-${id}`}
        color={buttonColor}
        type="button"
        appearance="solid"
        size="sm"
        rounded
        leftIcon={{
          name: 'arrow_left'
        }}
      />
      <Button
        className={`next-carousel-${id}`}
        color={buttonColor}
        type="button"
        appearance="solid"
        size="sm"
        rounded
        leftIcon={{
          name: 'arrow_right'
        }}
      />
    </div>
  )
}

export default SwiperNav
