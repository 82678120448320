import { YouTubeProps } from 'react-youtube'
import { extractYouTubeParams } from '@/utils/youtube'
import {
  YoutubeVideoVariantProps,
  youtubeVideo
} from './youtube-video.variants'

export type useYoutubeVideoProps = YoutubeVideoVariantProps & 
Pick<YouTubeProps, 'onReady' | 'onPlay' | 'onPause' | 'onStateChange'> & 
{
  className?: string
  containerClassName?: string
  url: string
  options?: YouTubeProps['opts']

}

export const useYoutubeVideo = (props: useYoutubeVideoProps) => {
  const { 
    className = '', 
    containerClassName = '', 
    url, 
    options, 
    appearance = 'default',
    onReady,
    onPlay,
    onPause,
    onStateChange
  } = props

  const styles = youtubeVideo({ appearance })

  const youtubeParams = extractYouTubeParams(url)
  const { youtubeId, startTime } = youtubeParams

  return {
    className,
    containerClassName,
    youtubeId,
    startTime,
    styles,
    options,
    onReady,
    onPlay,
    onPause,
    onStateChange
  }
}
