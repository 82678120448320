'use client'

import { useMemo, useState, useCallback } from 'react'
import { useEffectOnce } from 'react-use'
import { BlockProps, DynamicReviews } from '@/types'
import { dynamicReviews } from './dynamic-reviews.variants'
import { getReviews } from '@/services/reviews'
import { Locale } from '@/config/locales'
import { useParams } from 'next/navigation'

export type useDynamicReviewsProps = BlockProps<
  DynamicReviews & {
    reviews: {
      loading: boolean
      data: any[]
    }
  }
>

export const useDynamicReviews = (props: useDynamicReviewsProps) => {
  const {
    className = '',
    sectionName = '',
    data: {
      theme = 'dark',
      cardAppearance = 'video_9_16',
      reviews,
      category,
      ...restData
    },
    ...componentProps
  } = props

  const params = useParams()

  const [state, setState] = useState<{ loading: boolean; data: any[] }>({
    loading: true,
    data: []
  })

  const fetchReviews = useCallback(async () => {
    if (!state.loading) setState((prev) => ({ ...prev, loading: true }))
    const data = await getReviews(params.locale as Locale, category)
    setState({ loading: false, data })
  }, [state, params, category])

  const styles = useMemo(
    () => dynamicReviews({ theme, cardAppearance }),
    [theme, cardAppearance]
  )

  useEffectOnce(() => {
    fetchReviews()
  })

  return {
    styles,
    className,
    sectionName,
    data: {
      theme,
      cardAppearance,
      reviews: state,
      category,
      ...restData
    },
    componentProps
  }
}
