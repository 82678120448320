import { tv, VariantProps } from '@/utils/tv'

export const skeleton = tv({
  slots: {
    base: [
      'relative',
      'overflow-hidden',
      'w-full',
      'before:opacity-100',
      'before:absolute',
      'before:inset-0',
      'before:-translate-x-full',
      'before:animate-[shimmer_2s_infinite]',
      'before:bg-gradient-to-r',
      'before:from-transparent',
      'before:to-transparent',
      'after:opacity-100',
      'after:absolute',
      'after:inset-0',
      'after:-z-10'
    ],
    content: ['opacity-0']
  },
  variants: {
    theme: {
      dark: {
        base: ['bg-gray-10', 'before:via-gray-10/30', 'after:bg-gray-40']
      },
      light: {
        base: ['bg-gray-10', 'before:via-gray-20', 'after:bg-gray-50']
      }
    },
    isLoaded: {
      false: {
        base: 'transition-background before:transition-opacity duration-300 before:duration-300 opacity-20',
        content: 'transition-opacity motion-reduce:transition-none'
      },
      true: {
        base: 'bg-transparent before:opacity-0 before:animate-none after:opacity-0 duration-0 before:duration-0',
        content: 'opacity-100 duration-0 '
      }
    },
    rounded: {
      false: '',
      true: ''
    }
  },
  defaultVariants: {
    theme: 'dark',
    isLoaded: false,
    rounded: false
  },
  compoundVariants: [
    {
      isLoaded: false,
      rounded: false,
      class: {
        base: 'rounded-sm'
      }
    },
    {
      isLoaded: false,
      rounded: true,
      class: {
        base: 'rounded-circle'
      }
    }
  ]
})

export type SkeletonVariantProps = VariantProps<typeof skeleton>
