import { tv, VariantProps } from '@/utils/tv'

export const youtubeVideoDynamic = tv({
  slots: {
    base: [
      'group',
      'outline-none',
      'overflow-hidden cursor-pointer',
      'focus:outline-acqua-500',
      'relative'
    ],
    skeleton: ['rounded-xl'],
    play: [
      'flex items-center justify-center',
      'group-hover:scale-105',
      'transition-all duration-300',
      'w-20 h-20',
      'rounded-full',
      'absolute z-10 inset-0',
      'm-auto',
      'bg-white',
      'pointer'
    ],
    icon: ['w-5 h-5 text-black ml-1'],
    image: ['w-full h-full object-center object-cover']
  },
  variants: {
    appearance: {
      default: {
        skeleton: ['aspect-w-16', 'aspect-h-9']
      },
      video_9_16: {
        skeleton: ['aspect-w-9', 'aspect-h-16']
      }
    }
  }
})

export type YoutubeVideoDynamicVariantProps = VariantProps<
  typeof youtubeVideoDynamic
>
