import { useEffect, useRef, useState } from 'react'
import YouTube, { YouTubeEvent } from 'react-youtube'

import { useYoutubeVideoProps } from '@/components/ui/youtube-video/use-youtube-video'
import {
  YoutubeVideoDynamicVariantProps,
  youtubeVideoDynamic
} from './youtube-video-dynamic.variants'

export type useYoutubeVideoDynamicProps = YoutubeVideoDynamicVariantProps &
  Pick<useYoutubeVideoProps, 'appearance'> & {
    data: Omit<useYoutubeVideoProps, 'appearance'> & { id: number }
  }

interface MoisesYtbPlayEvent extends CustomEvent {
  detail: {
    id: number
  }
}

declare global {
  interface WindowEventMap {
    'moises-ytb-player::playing': MoisesYtbPlayEvent
  }
}

export const useYoutubeVideoDynamic = (props: useYoutubeVideoDynamicProps) => {
  const {
    appearance,
    data: { id }
  } = props

  const youtubeVideoRef = useRef<YouTubeEvent | null>(null)

  const [paused, setPaused] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)

  const handlePlayVideo = () => {
    youtubeVideoRef.current?.target.playVideo()
  }

  const handleStateChange = (event: YouTubeEvent<number>) => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      window.dispatchEvent(
        new CustomEvent('moises-ytb-player::playing', { detail: { id } })
      )
      setPaused(false)
    }
    if (
      [YouTube.PlayerState.PAUSED, YouTube.PlayerState.ENDED].includes(
        event.data
      )
    ) {
      setPaused(true)
    }
  }

  const handlePauseVideos = (event: CustomEvent) => {
    if (event.detail.id !== id) {
      youtubeVideoRef?.current?.target.pauseVideo()
    }
  }

  const handleOnReady = (event: YouTubeEvent) => {
    youtubeVideoRef.current = event
    setIsLoaded(true)
  }

  useEffect(() => {
    window?.addEventListener('moises-ytb-player::playing', handlePauseVideos)

    return () => {
      window?.removeEventListener(
        'moises-ytb-player::playing',
        handlePauseVideos
      )
    }
  }, [])

  const styles = youtubeVideoDynamic({ appearance })

  return {
    ...props,
    styles,
    youtubeVideoRef,
    paused,
    isLoaded,
    onStateChange: handleStateChange,
    onPlay: handlePlayVideo,
    onReady: handleOnReady
  }
}
