'use client'

import { Text } from '@/components/ui/text'
import DynamicReviewsSwiper from "./dynamic-reviews.swiper"
import { useDynamicReviews, useDynamicReviewsProps } from "./use-dynamic-reviews"

function DynamicReviews (props: useDynamicReviewsProps) {
    const { styles, sectionName, data } = useDynamicReviews(props)
    const { theme, title } = data
    
    return (
        <section 
            data-component-name={sectionName}
            className={styles.base()}
        >
            <div className={styles.wrapper()}>
                <header className={styles.header()}>
                    <Text
                        theme={theme}
                        tag="h2"
                        size="h3"
                        weight="medium"
                        className={styles.title()}
                    >
                        {title}
                    </Text>
                </header>

                <DynamicReviewsSwiper 
                    className={styles.carousel()}
                    sectionName={sectionName}
                    navClassName={styles.nav()}
                    data={data} 
                />
            </div>
        </section>
    )
}

export default DynamicReviews