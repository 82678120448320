'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import { QuoteCard, QuoteCardProps } from '@/components/ui/quote-card'
import {
  YoutubeVideoDynamic,
  YoutubeVideoDynamicProps
} from '@/components/ui/youtube-video-dynamic'
import { SwiperNav } from '@/components/ui/swiper-nav'
import { useDynamicReviewsProps } from './use-dynamic-reviews'
import { ReviewCard, ReviewCardProps } from '@/components/ui/review-card'

export type DynamicReviewsSwiperProps = Pick<
  useDynamicReviewsProps,
  'className' | 'sectionName' | 'data'
> & {
  navClassName?: string
}

const cardAppearanceMap = {
  review: {
    component: ReviewCard,
    props: {
      cardAppearance: 'ghost'
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1199: {
        slidesPerView: 4,
        spaceBetween: 24
      }
    }
  },
  quote: {
    component: QuoteCard,
    props: {},
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1199: {
        slidesPerView: 3,
        spaceBetween: 24
      }
    }
  },
  video_16_9: {
    component: YoutubeVideoDynamic,
    props: {
      appearance: 'default'
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1199: {
        slidesPerView: 2,
        spaceBetween: 24
      }
    }
  },
  video_9_16: {
    component: YoutubeVideoDynamic,
    props: {
      appearance: 'video_9_16'
    },
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1199: {
        slidesPerView: 4,
        spaceBetween: 24
      }
    }
  }
}

const DynamicReviewsSwiper = ({
  className = '',
  navClassName = '',
  sectionName,
  data
}: DynamicReviewsSwiperProps) => {
  const { id, theme, cardAppearance, reviews, category } = data

  const dynamicReviewData =
    category === 'user'
      ? cardAppearanceMap.review
      : cardAppearanceMap[cardAppearance]
  const DynamicReviewComponent = dynamicReviewData.component

  return (
    <Swiper
      className={className}
      modules={[Navigation]}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
      simulateTouch
      spaceBetween={40}
      breakpoints={dynamicReviewData.breakpoints}
    >
      {reviews.data.map((review, index) => (
        <SwiperSlide
          key={`review-card-${index}-${review.id}`}
          className="!h-auto"
        >
          {/* @ts-ignore */}
          <DynamicReviewComponent
            {...(dynamicReviewData.props as
              | QuoteCardProps
              | ReviewCardProps
              | YoutubeVideoDynamicProps)}
            data={review}
            theme={theme}
            sectionName={sectionName}
          />
        </SwiperSlide>
      ))}
      <SwiperNav id={id} className={navClassName} theme={theme} />
    </Swiper>
  )
}

export default DynamicReviewsSwiper
