import { strapiFetch } from '@/services/client'
import { Locale } from '@/config/locales'
import { isPreview } from '@/utils/env'

const getReviews = async (
  locale: Locale,
  category?: string,
  limit = -1,
) => {
  const params: Record<string, string | number> = {
    fallback: locale,
    _limit: limit,
    _sort: 'created_at:desc'
  }

  if (!!category) {
    params['category'] = category
  }

  return await strapiFetch<any[]>({
    path: `reviews`,
    params,
    options: {
      next: {
        revalidate: !isPreview ? 72000 : undefined // 20 hours
      }
    }
  })
}

export default getReviews
