import { useSkeleton, useSkeletonProps } from './use-skeleton'

export type SkeletonProps = useSkeletonProps

const Skeleton = (props: SkeletonProps) => {
  const { className, children, styles, componentProps } = useSkeleton(props)

  return (
    <div className={styles.base({ className })} {...componentProps}>
      {!!children && <div className={styles.content()}>{children}</div>}
    </div>
  )
}

export default Skeleton
