'use client'

import { Icon } from '@/components/ui/icon'
import { YoutubeVideo } from '@/components/ui/youtube-video'
import { Skeleton } from '@/components/ui/skeleton'

import {
  useYoutubeVideoDynamic,
  useYoutubeVideoDynamicProps
} from './use-youtube-video-dynamic'

export type YoutubeVideoDynamicProps = useYoutubeVideoDynamicProps

const YoutubeVideoDynamic = (props: YoutubeVideoDynamicProps) => {
  const {
    appearance,
    data: { url },
    styles,
    paused,
    isLoaded,
    onReady,
    onPlay,
    onStateChange
  } = useYoutubeVideoDynamic(props)

  return (
    <div className={styles.base()}>
      <Skeleton className={styles.skeleton()} isLoaded={isLoaded}>
        {paused && (
          <button type="button" onClick={onPlay} className={styles.play()}>
            <Icon name="play" className={styles.icon()} />
          </button>
        )}
        <YoutubeVideo
          url={url}
          containerClassName="w-full"
          appearance={appearance}
          onReady={onReady}
          onStateChange={onStateChange}
          options={{
            playerVars: {
              controls: 0,
              fs: 0,
              loop: 1,
              rel: 0
            }
          }}
        />
      </Skeleton>
    </div>
  )
}

export default YoutubeVideoDynamic
