import { tv, VariantProps } from '@/utils/tv'

export const youtubeVideo = tv({
  slots: {
    base: ['rounded-xl'],
    video: []
  },
  variants: {
    appearance: {
      default: {
        video: ['aspect-w-16', 'aspect-h-9']
      },
      video_9_16: {
        video: ['aspect-w-9', 'aspect-h-16']
      }
    }
  },
})

export type YoutubeVideoVariantProps = VariantProps<typeof youtubeVideo>
